<template>
  <!-- eslint-disable vue/no-v-html -->
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <CorporateHeader />
      <div class="kds-content-block">
        <p
          class="kds-typography-display-small"
          v-html="$t('b2b_email_code_welcome_text', { name: corporateName })"
        />
        <p
          class="kds-typography-paragraph-large"
          v-html="$t('b2b_email_code_instructions_intro')"
        />
      </div>
      <div class="kds-content-block">
        <div>
          <h4
            class="kds-typography-title-medium"
            v-html="$t('b2b_email_code_instructions_step_1_title')"
          />
          <p
            class="kds-typography-ui-subtle-large"
            v-html="$t('b2b_email_code_instructions_step_1_text')"
          />
        </div>
        <div>
          <h4
            class="kds-typography-title-medium"
            v-html="$t('b2b_email_code_instructions_step_2_title')"
          />
          <p
            class="kds-typography-ui-subtle-large"
            v-html="$t('b2b_email_code_instructions_step_2_text')"
          />
        </div>
        <div>
          <h4
            class="kds-typography-title-medium"
            v-html="$t('b2b_email_code_instructions_step_3_title')"
          />
          <p
            class="kds-typography-ui-subtle-large"
            v-html="$t('b2b_email_code_instructions_step_3_text')"
          />
        </div>
      </div>
      <EmailField
        :default-value="formData.emailCode"
        :show-errors="showErrors"
        :error-message="customErrorMessage"
        :autofocus="true"
        data-q-a="email"
        @input="handleTextInputChange"
      />
    </div>
    <template #actions>
      <BaseButton
        :text="$t('b2b_email_code_submit')"
        :disabled="loadingButtonState === 'DISABLED'"
        data-qa="check-email-code"
        @click="checkEmailCode"
      />
    </template>
  </ResponsiveLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { useI18n } from 'petite-vue-i18n'

import ROUTE from '@us/app/router/appModule/names'

import EmailField from '@shared/components/EmailField.vue'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import env from '@shared/env.js'

export default {
  name: 'B2BEmail',
  components: {
    ResponsiveLayout,
    EmailField,
    CorporateHeader,
    BaseButton,
  },
  props: {
    configureStickyHeader: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      emailCodeState: false,
      customErrorMessage: '',
      showErrors: false,
      loadingButtonState: 'ACTIVE',
    }
  },
  computed: {
    ...mapGetters({
      corporate: 'corporate',
      verificationParams: 'verificationParams',
      formData: 'formData',
    }),
    corporateName() {
      return this.corporate.title || ''
    },
    isFormValid() {
      return this.emailCodeState
    },
  },
  created() {
    const { email: emailFromStore } = this.verificationParams
    if (this.voucher && this.voucher.emailCode) {
      this.$store.commit('formData', {
        emailCode: this.voucher.emailCode,
      })
    }
    if (this.$route.query.email) {
      this.$store.commit('formData', {
        emailCode: this.$route.query.email,
      })
    }
    if (this.$route.params?.emailCode) {
      this.$store.commit('formData', {
        emailCode: this.$route.params.emailCode,
      })
    }
    if (emailFromStore) {
      this.$store.commit('formData', { emailCode: emailFromStore })
    }

    if (this.formData.emailCode) {
      this.checkEmailCode()
    }
  },
  methods: {
    ...mapActions(['checkEmail']),
    redirectToNext(voucher) {
      this.loadingButtonState = 'ACTIVE'
      this.$router.push({
        name: ROUTE.B2B_SIGN_UP,
        params: {
          corp_name: voucher.corporate_name,
        },
      })
    },
    checkEmailCode(e) {
      if (e) {
        e.preventDefault()
      }
      this.showErrors = false

      if (!this.isFormValid) {
        this.showErrors = true
        return false
      }

      this.loadingButtonState = 'PROCESSING'

      this.checkEmail({
        email: this.formData.emailCode,
        corporate: this.corporate.key,
      })
        .then(this.redirectToNext)
        .catch((error) => {
          this.loadingButtonState = 'ACTIVE'
          if (error.response && error.response.status === 403) {
            this.showErrors = true
            this.customErrorMessage = this.t(
              'b2b_email_code_not_valid_with_support_email',
              {
                VUE_APP_SUPPORT_EMAIL: env.VITE_SUPPORT_EMAIL,
              },
            )
          }
        })
    },
    handleTextInputChange({ name, value, state }) {
      this.customErrorMessage = ''

      switch (name) {
        case 'email':
          this.$store.commit('formData', { emailCode: value })
          this.emailCodeState = state
          break

        default:
          break
      }
    },
  },
}
</script>

<style lang="scss"></style>
