import ROUTE from '@us/app/router/appModule/names'
import env from '@shared/env.js'

export default [
  {
    path: '/b2b',
    name: ROUTE.B2B,
    component: () => import('@us/app/views/B2B.vue'),
  },
  {
    path: '/b2b/partners',
    name: ROUTE.B2B_PARTNERS,
    component: () => import('@us/app/views/B2BPartners.vue'),
    meta: {
      name: 'onb_employer_search',
    },
  },
  {
    path: '/uhc_vpt_employer_search',
    name: ROUTE.UHC_VPT_EMPLOYER_SEARCH,
    component: () => import('@us/app/views/UhcVptEmployerSearch.vue'),
    meta: {
      name: 'uhc_vpt_employer_search',
    },
  },
  {
    path: '/uhc_vpt_insurance_selection',
    name: ROUTE.UHC_VPT_INSURANCE_SELECTION,
    component: () => import('@us/app/views/UhcVptInsuranceSelection.vue'),
    meta: {
      name: 'uhc_vpt_insurance_selection',
    },
  },
  {
    path: '/uhc_vpt_primer',
    name: ROUTE.UHC_VPT_PRIMER,
    component: () => import('@us/app/views/UhcVptPrimer.vue'),
    meta: {
      name: 'uhc_vpt_primer',
    },
  },
  {
    path: '/b2b/information',
    name: ROUTE.B2B_INFORMATION_GENERIC,
    component: () => import('@us/app/views/B2BLandingPage.vue'),
    meta: {
      layout: 'B2BLandingLayout',
    },
  },
  {
    path: '/b2b/:corp_name',
    name: ROUTE.B2B_SELECT_VERIFICATION_METHOD,
  },
  {
    path: '/b2b/:corp_name/voucher/:voucherCode?',
    name: ROUTE.B2B_VOUCHER,
    component: () => import('@us/app/views/B2BVoucher.vue'),
  },
  {
    path: '/b2b/:corp_name/email/:emailCode?',
    name: ROUTE.B2B_EMAIL,
    component: () => import('@us/app/views/B2BEmail.vue'),
  },
  {
    path: '/b2b/:corp_name/eligibility_verification',
    redirect: { name: ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION },
  },
  {
    path: '/b2b/:corp_name/eligibility_list',
    name: ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION,
    component: () =>
      import('@us/app/views/B2BCorporateEligibilityVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corp_name/solera-verification',
    name: ROUTE.B2B_SOLERA_VERIFICATION,
    component: () => import('@us/app/views/SoleraVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corp_name/uhchub-verification',
    name: ROUTE.B2B_UHCHUB_VERIFICATION,
    component: () => import('@us/app/views/UhchubVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corp_name/sign-up',
    name: ROUTE.B2B_SIGN_UP,
    component: () => import('@us/app/views/B2BSignUp.vue'),
    meta: {
      name: 'onb_account_creation',
    },
  },
  {
    path: '/b2b/:corp_name/login',
    name: ROUTE.B2B_LOGIN,
    component: () => import('@us/app/views/B2BLogin.vue'),
    meta: {
      name: 'onb_signin',
    },
  },
  {
    path: '/b2b/:corp_name/download-link',
    name: ROUTE.B2B_DOWNLOAD_LINK,
    component: () => import('@us/app/views/DownloadLink.vue'),
    meta: {
      layout: 'B2BDownloadLayout',
      name: 'onb_download_prompt',
    },
  },
  {
    path: '/b2b/:corp_name/download-instructions',
    redirect: { name: ROUTE.B2B_DOWNLOAD_LINK },
  },
  {
    path: '/b2b/:corp_name/sso-initiated',
    name: ROUTE.SAML_SSO_INITIATED,
    component: () => import('@us/app/views/SamlSSOInitiated.vue'),
  },
  {
    path: '/b2b/:corp_name/sso',
    redirect: { name: ROUTE.SAML_SSO_COMPLETED },
  },
  {
    path: '/b2b/:corp_name/sso-completed',
    name: ROUTE.SAML_SSO_COMPLETED,
    component: () => import('@us/app/views/SamlSSOCompleted.vue'),
  },
  {
    path: '/b2b/:corp_name/sso-error',
    name: ROUTE.SAML_SSO_ERROR,
    component: () => import('@us/app/views/SamlSSOError.vue'),
  },
  {
    path: '/b2b/:corp_name/information',
    name: ROUTE.B2B_INFORMATION,
    meta: {
      layout: 'B2BLandingTestLayout',
    },
  },
  {
    path: '/b2b/:corp_name/information/check-in',
    name: ROUTE.B2B_SIMPLE_CHECK_IN,
    component: () => import('@us/app/views/B2BSimpleLandingPage.vue'),
    meta: {
      layout: 'B2BSimpleLandingLayout',
    },
  },
  {
    path: '/b2b/:corp_name/information/plan-outline',
    name: ROUTE.B2B_SIMPLE_PLAN_OUTLINE,
    component: () => import('@us/app/views/B2BSimpleLandingPage.vue'),
    meta: {
      layout: 'B2BSimpleLandingLayout',
    },
  },
  {
    path: '/b2b/:corp_name/information/testimonial',
    name: ROUTE.B2B_SIMPLE_TESTIMONIAL,
    component: () => import('@us/app/views/B2BSimpleLandingPage.vue'),
    meta: {
      layout: 'B2BSimpleLandingLayout',
    },
  },
  {
    path: '/b2b/:corp_name/subscribe-not-possible',
    name: ROUTE.B2B_VERIFY_NOT_POSSIBLE_FOR_EXISTING_USER,
    component: () =>
      import('@us/app/views/B2BVerifyNotPossibleForExistingUser.vue'),
  },
  ...(env.isProd
    ? []
    : [
        {
          path: '/uhchub/init',
          name: ROUTE.UHCHUB_INIT,
          component: () => import('@us/app/views/UhchubInit.vue'),
        },
        {
          path: '/personifyhealth/init',
          name: ROUTE.PERSONIFY_HEALTH_INIT,
          component: () => import('@us/app/views/PersonifyHealthInit.vue'),
        },
      ]),
]
